<template>
  <div
    class="flex flex-col items-center"
    v-show="(Object.keys(billing).length>2 && billing.temp!==true) && emailVerified"
  >
    <BrandIcon class="text-red h-11 w-11"/>

    <div class="mt-3 text-sm leading-6 text-primary text-center">
      Welcome to Spext 🙂
      <br>
      To begin, upload a file to transcribe it
    </div>

    <div class="mt-3 text-sm leading-6 text-primary-500">
      (You can paste a link to your media too)
    </div>

    <Button
      @click="showUploadModal"
      classname="mt-4 pr-2.75"
    >
      Upload file
    </Button>
  </div>
</template>

<script>
import Button from '@/components/base/buttons/Button.vue'
import BrandIcon from '@/components/base/icons/Brand.vue'

export default {
  props: ['billing', 'emailVerified'],
  components: {
    Button,
    BrandIcon
  },
  methods: {
    showUploadModal () {
      this.$emit('showUploadModal')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
